import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "config/reducer.utils";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedMedia } from "./media.query";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedVoucher {
    "voucher_id"?: string,
    "voucher_name"?: string,
    "voucher_excerpt"?: string,
    "voucher_description"?: string,
    "voucher_code"?: string,
    "voucher_valid_from"?: any,
    "voucher_valid_to"?: any,
    "voucher_count"?: number,
    "voucher_count_max"?: number,
    "voucher_count_max_per_user"?: number,
    "voucher_thumbnail"?: string,
    "voucher_category"?: string,
    voucher_value?: number;
    voucher_value_max?: number;
    voucher_value_unit?: string;

    is_multiple?: number,
    is_private?: number,
    is_active?: number,

    createdAt?: bigint,

    voucher_thumbnail_to_media?: TypedMedia,

    rules?: {
        voucher_rule_key: string,
        voucher_rule_value: string,
    }[],
    voucher_rule?: any[]
}


type IQuery = TypedVoucher & IQueryParams;


/*
* List of all app
*/
export function useGetVouchers(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["voucher/fetch_entity_list"],
        queryFn: () => axios.get<TypedVoucher[]>(`/voucher${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}



export function useGetVoucher(voucher_id: string) {
    return useQuery({
        queryKey: ['voucher/fetch_entity'],
        queryFn: () => axios.get<TypedVoucher>(`/voucher/${voucher_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}

export function useMutateGetVoucher() {
    return useMutation({
        mutationKey: ['voucher/fetch_entity'],
        mutationFn: (voucher_id: string) => axios.get<TypedVoucher>(`/voucher/${voucher_id}`).then((response) => response.data),
    });
}


export function useUpdateVoucher() {
    return useMutation({
        mutationKey: ['voucher/update_entity'],
        mutationFn: ({ voucher_id, ...rest }: TypedVoucher) => axios.patch<TypedVoucher>(`/voucher/${voucher_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['voucher/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['voucher/fetch_entity'] });
        }
    });
}


export function useCreateVoucher() {
    return useMutation({
        mutationKey: ['voucher/create_entity'],
        mutationFn: (entity: TypedVoucher) => axios.post<TypedVoucher>(`/voucher`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['voucher/fetch_entity_list'] });
        }
    });
}


export function useDeleteVoucher() {
    return useMutation({
        mutationKey: ['voucher/delete_entity'],
        mutationFn: (app_id: string) => axios.delete<any>(`/voucher/${app_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['voucher/fetch_entity_list'] });
        }

    });
}